import React, { useEffect } from "react";
type physicsObject = {
  x: number;
  y: number;
  z?: number;
  wobble: number;
  velocity: number;
  angle2D: number;
  angle3D: number;
  tiltAngle: number;
};

type Fetti = {
  physics: physicsObject;
  element: HTMLElement;
};

type ConfettiOptions = {
  angle?: number;
  decay?: number;
  spread?: number;
  startVelocity?: number;
  elementCount?: number;
};

const Confetti = () => {
  const confettiCreator = () => {
    const btn = document.querySelector("[data-setmore]") as HTMLSpanElement;
    const currentColor = "#1D90F5";

    function createElements(t: HTMLElement, e: number) {
      return Array.from({ length: e }).map((e, s) => {
        const n = document.createElement("div");
        n.classList.add("fetti");
        const o = currentColor;
        n.style.backgroundColor = o;
        n.style.pointerEvents = "none";
        n.style.width = "10px";
        n.style.height = "10px";
        n.style.borderTopLeftRadius = "10px";
        n.style.borderTopRightRadius = "8px";
        n.style.borderBottomLeftRadius = "6px";
        n.style.borderBottomRightRadius = "12px";
        n.style.position = "absolute";
        n.style.left = "50%";
        n.style.top = "50%";
        n.style.opacity = "1";
        t.appendChild(n);
        return n;
      });
    }

    function randomPhysics(t: number, e: number, s: number) {
      const n = t * (Math.PI / 180);
      const o = e * (Math.PI / 180);
      return {
        x: 0,
        y: 0,
        wobble: 10 * Math.random(),
        velocity: 0.65 * s + Math.max(Math.random(), 0.35) * s,
        angle2D: -n + (0.5 * o - Math.random() * o),
        angle3D: -Math.PI / 4 + Math.random() * (Math.PI / 2),
        tiltAngle: Math.random() * Math.PI,
      };
    }

    function updateFetti(t: Fetti, e: number, s: number) {
      t.physics.x += Math.cos(t.physics.angle2D) * t.physics.velocity;
      t.physics.y += Math.sin(t.physics.angle2D) * t.physics.velocity;
      if (t.physics.z)
        t.physics.z += Math.sin(t.physics.angle3D) * t.physics.velocity;
      t.physics.wobble += 0.05;
      t.physics.velocity *= s;
      t.physics.y += 3;
      t.physics.tiltAngle += 30.1;

      const { x: n, y: o, tiltAngle: a, wobble: i } = t.physics;
      const l = `translate3d(${n + 10 * Math.cos(i)}px, ${
        o + 10 * Math.sin(i)
      }px, 0) rotateZ(${a}deg)`;

      (t.element.style.transform = l),
        (t.element.style.opacity = `${Math.min(1 - 4.2 * e, 100 - o)}`);
    }

    function animate(t: HTMLElement, e: Fetti[], s: number) {
      const n = 200;
      let o = 0;
      requestAnimationFrame(function a() {
        e.forEach((t) => updateFetti(t, o / n, s)),
          (o += 1) < n
            ? requestAnimationFrame(a)
            : e.forEach((e) => t.removeChild(e.element));
      });
    }

    function confetti(
      targetElement: HTMLElement,
      options: ConfettiOptions = {}
    ) {
      const {
        angle = 90,
        decay = 0.85,
        spread = 80,
        startVelocity = 14,
        elementCount = 12,
      } = options;
      animate(
        targetElement,
        createElements(targetElement, elementCount).map((targetElement) => ({
          element: targetElement,
          physics: randomPhysics(angle, spread, startVelocity),
        })),
        decay
      );
    }
    if (btn) {
      btn.addEventListener("mouseenter", () => confetti(btn));
    }
  };

  useEffect(() => {
    confettiCreator();
  });

  return <span data-setmore="button"></span>;
};

export default Confetti;
