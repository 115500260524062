import { EuclidCircularSemibold, Inter } from "pages/_app";
//-----**COLORS - [primitive, derived]**--------//
export const colors = {};
colors.primitive = {
  black: {
    100: "#181818",
    200: "#f6f8fc",
    300: "#d9e1ee",
    400: "#8495b1",
    500: "#556784",
    600: "#3b4c66",
    700: "#142640",
    800: "#000",
    900: "#303a47",
    1000: "#8495b2",
    1100: "#191f29",
    1200: "#1a1f23",
  },
  blue: {
    30: "#EBF8FF",
    50: "#1B9FE0",
    100: "#00adef",
    200: "#1d90f5",
    300: "#126ab8",
    400: "#ebf4ff",
    500: "#8ca0b8",
    600: "#1265b8",
    700: "#086cd0",
  },
  green: {
    100: "#ecfff7",
    200: "#19af72",
    300: "#107f52",
    400: "#068f86",
    500: "#ceebe9",
    600: "#eefcf6",
    700: "#156b48",
    800: "#1B3D32",
    900: "#0F2922",
    light: "#48A36C",
  },
  grey: {
    100: "#d2d3d4",
    200: "#6d7175",
    300: "#8996a3",
    400: "#aaa",
    500: "#ccc",
    600: "#edf0f2",
    700: "#f5f8fa",
    800: "#556270",
    900: "#adbbd2",
    1000: "#dde3f0",
    1100: "#d3d9e5",
    1200: "#f3f6fc",
    1300: "#dedede",
    1400: "#d6dce0",
    1500: "#dbdbdb",
    1600: "#586874",
    1700: "#bcc4cc",
  },
  red: {
    100: "#dd2727",
    200: "#e04209",
    300: "#f57f31",
    400: "#e9948f",
  },
  violet: {
    100: "#3c60da",
    200: "#8b54a6",
    300: "#8955a3",
    400: "#e9ddf0",
  },
  white: {
    default: "#fff",
    100: "#f5faff",
    200: "#d2d9e0",
  },
  yellow: {
    default: "#ffb41f",
    100: "#ffb420",
    200: "#fff0d2",
  },
  orange: {
    100: "#ffe7d6",
    200: "#e04209",
  },
  shadow: {
    default: "rgba(22, 45, 61, 0.2)",
    black: "rgba(0, 0, 0, 0.1)",
    grey: "rgba(246, 248, 252, 0.5)",
    deep: "rgba(24, 24, 24, 0.9)",
  },
};
colors.derived = {
  brand: colors.primitive.blue[700],
  primary: colors.primitive.black[600],
  error: colors.primitive.red[100],
  shadow: colors.primitive.shadow.default,
};

//-----**FONTS - [ size, weight ]**--------//
export const font = {};
font.size = {
  sm: {
    1: "10px",
    2: "12px",
    3: "14px",
    4: "16px",
  },
  md: {
    1: "18px",
    2: "20px",
    3: "24px",
    4: "28px",
  },
  lg: {
    1: "32px",
    2: "38px",
    2.5: "40px",
    3: "48px",
    4: "56px",
    5: "64px",
  },
  xl: {
    1: "75px",
    2: "80px",
  },
};
font.weight = {
  normal: "400",
  semiBold: "600",
  bold: "700",
  extraBold: "900",
};
font.style = {
  primaryFont: `${EuclidCircularSemibold.style.fontFamily}`,
  secondaryFont: `${Inter.style.fontFamily}`,
};
//-----**LINE HEIGHT - [ 1unit = 4px ] **--------//
export const lineHeight = {
  4: "16px",
  5: "20px",
  6: "24px",
  7: "28px",
  8: "32px",
  9: "36px",
  10: "40px",
  11: "44px",
  12: "48px",
  16: "64px",
  18: "72px",
  20: "80px",
  22: "88px",
};

//-----**MEDIA QUERIES - [ viewport, device ]**--------//
export const viewport = {};
viewport.mobile = {
  sm: "360px",
  md: "576px",
  lg: "640px",
};
viewport.tablet = {
  sm: "762px",
  md: "992px",
  default: "768px",
};
viewport.desktop = {
  sm: "1200px",
  md: "1300px",
};
