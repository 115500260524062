import { useEffect, useState } from "react";
import { TREE_COUNTER_URL } from "@util/constants";

type TreeCounterProps = {
  data: {
    icon: string;
    text: string;
  };
};

const TreeCounter = ({ data }: TreeCounterProps) => {
  const [count, setCount] = useState<string>("1,650,483");

  useEffect(() => {
    fetch(TREE_COUNTER_URL)
      .then((response) => response.json())
      .then((treeCountData) => {
        if (treeCountData !== null) {
          setCount(treeCountData.count);
        }
      })
      .catch((error) => {
        console.error(
          "Error while fetching tree count from json file: ",
          error
        );
      });
  }, []);
  return (
    <div className="trees-count lg:d-fx">
      <figure dangerouslySetInnerHTML={{ __html: data.icon }}></figure>
      <p className="ff-euclid fw-700">
        <span className="trees__count">{count}</span>
        {data.text}
      </p>
    </div>
  );
};

export default TreeCounter;
