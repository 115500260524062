import Link from "next/link";
import { ExternalLink } from "@components/atoms/ExternalLink";
import CookieBanner from "../../molecules/common/CookieBanner";
import TreeCounter from "../../molecules/common/TreeCounter";
import TermsAndPrivacyPopUp from "./TermsAndPrivacy";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "../../../styles/cssinjs/helpers/variables.js";
import { CONSTANTS } from "../../../util/utils";

export default function Footer({ data }) {
  const assetsBaseUrl = CONSTANTS.assetsBaseUrl.cdn;
  const openFooterNav = () => {
    window.document.body.classList.toggle("open-industry-nav");
  };
  const LanguageDropdown = () => {
    return (
      <div id="LangDropdown" className="dropdown">
        <div className="dropdown__view">
          <span className="dropdown__lang">Language:</span>{" "}
          <span className="dropdown__btn">
            <span className="dropdown__active-lang">
              {data.currentLanguage}
            </span>
          </span>{" "}
          <div className="dropdown-footer-default">
            <ul className="page-links-container">
              {data.languages.map((item, index) => {
                return (
                  <li key={index}>
                    <a href={item.href}>{item.name}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <style global jsx>{`
          .dropdown__view {
            position: relative;
            bottom: 0;
          }
          .dropdown__active-lang {
            color: ${colors.primitive.white.default};
            cursor: pointer;
            text-decoration: underline;
          }
          .dropdown-footer-default {
            min-width: 95px;
            display: none;
            margin: 0 50px 14px 50px;
            box-shadow: 0 6px 12px 0 rgba(48, 58, 71, 0.05),
              0 16px 24px 0 rgba(48, 58, 71, 0.15);
            border-radius: 8px;
            background-color: ${colors.primitive.black[500]};
            padding: 8px 16px 12px 16px;
            position: absolute;
            bottom: 0;
            overflow: visible;
          }
          .dropdown-footer-default::before {
            content: "";
            opacity: 0;
          }
          .dropdown:hover .dropdown-footer-default {
            display: block;
          }
          .dropdown-footer-default .page-links-container li a {
            line-height: ${lineHeight[7]};
            font-weight: ${font.weight.normal};
            color: ${colors.primitive.white.default};
          }
          @media (max-width: ${viewport.tablet.md}) {
            .dropdown:hover .dropdown-footer-default {
              width: 100px;
            }
            .dropdown:hover .dropdown-footer-default.active {
              display: block;
            }
          }
        `}</style>
      </div>
    );
  };
  const BrandingUI = () => {
    const FooterLogo = () => {
      return (
        <figure id="setmore-logo-footer" className="lg:mb-9 md:mb-5">
          <Link href="/" prefetch={false}>
            <img
              loading="lazy"
              src="https://assets.setmore.com/website/v2/images/logo/setmore-logo-white.svg"
              alt="Setmore logo in white color"
              width="156"
              height="24"
            />
          </Link>
          <style jsx>{`
            @media (max-width: ${viewport.tablet.md}) {
              figure {
                width: 130px;
              }
            }
          `}</style>
        </figure>
      );
    };
    const SocialNav = () => {
      return (
        <ul className="social-links lg:mb-4 md:mb-5 md:mt-2.5">
          {data.socialNav.map((item, index) => {
            return (
              <li key={index}>
                <a
                  aria-label={item.ariaLabel}
                  className={`icon icon-${item.name}`}
                  href={item.href}
                  id={item.id}
                  target="_blank"
                  rel="noreferrer"
                  dangerouslySetInnerHTML={{ __html: item.icon }}
                ></a>
              </li>
            );
          })}
          <style jsx>{`
            .social-links li {
              display: inline-block;
              vertical-align: middle;
              margin-right: 18px;
            }
          `}</style>
        </ul>
      );
    };
    const BecauseAW = () => {
      return (
        <div className="aw-logo md:mb-10">
          <span className="mb-1">{data.because.text} </span>{" "}
          <a
            target="_blank"
            href="https://anywhereworks.com"
            aria-label="Goto Anywhereworks"
            id="aw-footer"
            rel="noreferrer noopener"
          >
            <i
              dangerouslySetInnerHTML={{
                __html: data.because.icon,
              }}
            ></i>
          </a>
          <style jsx>{`
            .aw-logo > * {
              display: inline-block;
              vertical-align: middle;
            }
            .aw-logo > a,
            .aw-logo > img {
              display: block;
              margin-top: 5px;
            }
          `}</style>
        </div>
      );
    };
    return (
      <div className="branding">
        <FooterLogo />
        <div className="contact-wrapper lg:mb-3">
          <a href="mailto:help@setmore.com" id="help-email-footer">
            help@setmore.com
          </a>
        </div>
        <SocialNav />
        <BecauseAW />
        <style global jsx>{`
          .branding figure {
            width: 156px;
          }
          .branding .contact-wrapper {
            line-height: ${lineHeight[6]};
          }
          @media (max-width: ${viewport.tablet.md}) {
            .branding {
              margin-bottom: 25px;
            }
            .branding > figure {
              width: 130px;
            }
          }
        `}</style>
      </div>
    );
  };
  const MainNav = () => {
    const IndustriesSubNav = ({ data }) => {
      return (
        <ul>
          {data.map((item, index) => {
            return (
              <li
                key={index}
                id={item.id ? `footer-industry-dropdown-${item.id}` : ""}
              >
                <Link href={item.href} prefetch={false}>
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      );
    };
    const Nav = ({ data, classes = "" }) => {
      return (
        <ul className={`${classes.length > 0 ? classes : ""}`}>
          {data.map((item, index) => {
            let id = item.id
              ? item.id
              : `${item.name.toLowerCase()}-link-footer`;
            return item.id === "solutions-link-footer" ? (
              <li
                id={id}
                key={index}
                className="industries md:mb-5"
                onClick={openFooterNav}
              >
                <span>{item.name}</span>
              </li>
            ) : (
              <li id={id} key={index}>
                <a href={item.href}>{item.name}</a>
              </li>
            );
          })}
          <style global jsx>{`
            .industries {
              cursor: pointer;
            }
            .industries span {
              position: relative;
              transform-origin: center;
            }
            .industries span::before {
              content: "";
              width: 5px;
              height: 5px;
              position: absolute;
              top: 7px;
              left: 110%;
              border: solid ${colors.primitive.white.default};
              border-width: 0 1px 1px 0;
              transform: rotate(45deg);
            }
          `}</style>
        </ul>
      );
    };
    return (
      <nav>
        <Nav data={data.mainNav[0]} />
        <div className="industries-nav">
          <IndustriesSubNav data={data.subNav.industries[0]} />
          <IndustriesSubNav data={data.subNav.industries[1]} />
          <IndustriesSubNav data={data.subNav.industries[2]} />
          <style global jsx>{`
            .industries-nav {
              display: flex;
              order: 4;
              width: 100%;
              padding-top: 0;
              transition: 0.7s all;
              max-height: 0;
              opacity: 0;
              visibility: hidden;
              border-top: 1px solid ${colors.primitive.shadow.grey};
              font-size: ${font.size.sm[3]};
            }
            .industries-nav > * {
              width: 33%;
            }
            .industries-nav li {
              margin-bottom: 15px;
            }
            @media (max-width: ${viewport.tablet.md}) {
              .industries-nav {
                display: block;
                border: none;
                padding-top: 0 !important;
              }
              .industries-nav > * {
                width: auto;
                margin-bottom: 15px;
              }
            }
          `}</style>
        </div>
        <Nav data={data.mainNav[1]} classes="md:mb-5" />
        <Nav data={data.mainNav[2]} />
      </nav>
    );
  };
  const DownloadUI = () => {
    return (
      <div className="sub-link-wrap lg:d-fx mt-1">
        <p className="md:mb-10">{data.treesUI.subtext}</p>
        <div className="md:mb-5 tap-targetfix">
          <p className="text-xs">
            <span>{data.downloadUI.label}</span>
            <ExternalLink
              href={data.downloadUI.apps[0].href}
              id="footer-ios-button"
            >
              {data.downloadUI.apps[0].name}
            </ExternalLink>
            <span className="mx-1">|</span>
            <ExternalLink
              href={data.downloadUI.apps[1].href}
              id="footer-playtstore-button"
            >
              {data.downloadUI.apps[1].name}
            </ExternalLink>
          </p>
        </div>
        <style jsx>{`
          .sub-link-wrap {
            color: ${colors.primitive.black[300]};
            font-size: ${font.size.sm[3]};
          }
          .sub-link-wrap > p:first-child {
            margin-right: auto;
          }
          .tap-targetfix span {
            color: ${colors.primitive.grey[300]};
          }
          @media (min-width: ${viewport.tablet.sm}) and (max-width: ${viewport
              .tablet.md}) {
            .sub-link-wrap > p:first-child {
              width: 25%;
              margin-right: 8px;
            }
          }
          @media (max-width: ${viewport.tablet.sm}) {
            .tap-targetfix .hover-link,
            .tap-targetfix .dark-footer-wrapper nav a,
            .dark-footer-wrapper nav .tap-targetfix a {
              line-height: ${lineHeight[12]};
            }
          }
        `}</style>
      </div>
    );
  };
  const LearnMoreUI = ({ data }) => {
    const BlueDot = () => {
      return (
        <p className="blue-dot-wrap">
          <span>{data.bluedot.urhere}</span>
          <ExternalLink
            href={data.bluedot.href}
            id={data.bluedot.id}
            className="blue-dot-link"
          >
            <span>{data.bluedot.pale}</span>
            <code className="blue-dot"></code>
          </ExternalLink>
        </p>
      );
    };
    return (
      <div className="learn-more">
        <span>{data.copyright}</span>
        <span className="mx-1">|</span>
        <ExternalLink id={data.co2.id} href={data.co2.href}>
          CO<sub className="subscript">2</sub>
          {data.co2.value}
        </ExternalLink>
        <span className="mx-1">|</span>
        <ExternalLink id={data.whyco2.id} href={data.whyco2.href}>
          {data.whyco2.text} CO<sub className="subscript">2</sub>{" "}
          {data.whyco2.subtext}
        </ExternalLink>
        <span className="mx-1">|</span>
        <LanguageDropdown />
        <BlueDot />
        <style global jsx>{`
          .learn-more {
            color: ${colors.primitive.black[300]};
            font-size: ${font.size.sm[3]};
            text-align: left;
            padding: 43px 0 0 0;
          }
          .learn-more > a {
            transition: 0.3s color;
          }
          .learn-more > a:hover {
            color: ${colors.primitive.white.default};
          }
          .learn-more > p {
            margin-left: auto;
            font-weight: ${font.weight.normal};
          }
          .learn-more > p span {
            vertical-align: middle;
          }
          .subscript {
            position: relative;
            bottom: -4px;
            font-size: ${font.size.sm[2]};
          }
          .blue-dot-link {
            vertical-align: middle;
          }
          .blue-dot-link:hover .blue-dot {
            transform: scale(1);
          }
          .blue-dot-link:hover .blue-dot::after {
            opacity: 1;
          }
          .blue-dot-link .blue-dot {
            margin-right: -8px;
          }
          .blue-dot {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: ${colors.derived.brand};
            transform: scale(0.5);
            transition: 0.5s transform;
          }
          .blue-dot::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            background: url(${assetsBaseUrl}/v2/images/gif/earth-2x.gif)
              no-repeat center;
            background-size: 24px 24px;
            opacity: 0;
            transition: 0.5s opacity;
          }
          @media (min-width: ${viewport.tablet.md}) {
            .learn-more {
              display: flex;
            }
          }
          @media (max-width: ${viewport.tablet.md}) {
            .learn-more > * {
              display: block;
              margin: 0;
              margin-bottom: 30px !important;
            }
            .learn-more .mx-1 {
              display: none;
            }
            .learn-more > span {
              display: block;
              margin-bottom: 10px;
            }
            .learn-more > p {
              margin-top: 10px;
            }
            .learn-more .pipe-3 {
              display: none;
            }
          }
          @media (max-width: ${viewport.mobile.sm}) {
            .learn-more > a {
              line-height: ${lineHeight[12]};
            }
          }
        `}</style>
      </div>
    );
  };

  return (
    <>
      <footer id="main-footer" className="is-default">
        <div className="container">
          <div className="dark-footer-wrapper lg:d-fx lg:pb-9">
            <BrandingUI />
            <MainNav />
          </div>
          <div className="mid-wrap lg:pb-5">
            <TreeCounter data={data.treesUI} />
            <DownloadUI />
          </div>
          <LearnMoreUI data={data.learnmoreUI} />
        </div>
        <style global jsx>{`
          #main-footer {
            background-color: ${colors.primitive.black[900]};
          }
          .is-default {
            font-size: ${font.size.sm[4]};
            padding: 80px 0 40px;
            background-color: ${colors.derived.primary};
            color: ${colors.primitive.white.default};
          }
          .is-default p {
            font-size: ${font.size.sm[3]};
            line-height: ${lineHeight[6]};
          }
          .mid-wrap {
            border-bottom: 1px solid ${colors.primitive.shadow.grey};
          }
          .mid-wrap .trees-count figure {
            width: 46px;
            margin-right: 12px;
          }
          .mid-wrap .trees-count > p {
            font-size: ${font.size.lg[1]};
            line-height: ${lineHeight[10]};
            color: ${colors.primitive.black[300]};
          }
          .dark-footer-wrapper {
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
            justify-content: space-between;
          }
          .dark-footer-wrapper nav {
            display: flex;
            flex-wrap: wrap;
            margin-left: auto;
            max-width: 660px !important;
            margin-left: 0 !important;
          }
          .dark-footer-wrapper nav > ul {
            width: 220px;
          }
          .dark-footer-wrapper nav > ul a {
            transition: 0.7s opacity;
          }
          .dark-footer-wrapper nav li {
            line-height: ${font.size.md[3]};
            margin-bottom: ${lineHeight[6]};
          }
          .dark-footer-wrapper nav li:last-child {
            margin-bottom: 40px;
          }
          .open-industry-nav .dark-footer-wrapper nav .industries span::before {
            transform: rotate(calc(45deg + 180deg));
            top: 9px;
          }
          .open-industry-nav .dark-footer-wrapper nav > ul a {
            opacity: 0.5;
          }
          .open-industry-nav nav > ul a::after {
            background-color: ${colors.primitive.grey[200]};
          }
          .open-industry-nav .dark-footer-wrapper nav .industries-nav {
            visibility: visible;
            opacity: 1;
            max-height: 250vh;
            padding-top: 40px;
          }
          .spanish-v2 .dark-footer-wrapper nav .industries-nav {
            width: 50%;
          }
          @media (max-width: ${viewport.tablet.md}) {
            .is-default {
              padding: 40px 0 80px;
            }
            .dark-footer-wrapper {
              display: block;
            }
            .dark-footer-wrapper nav {
              margin-left: 0;
              display: block;
              width: auto;
            }
            .dark-footer-wrapper nav > ul {
              width: auto;
            }
            .dark-footer-wrapper nav li {
              margin-bottom: 20px;
            }
            .dark-footer-wrapper nav li:last-child {
              margin-bottom: 0;
            }
            .mid-wrap {
              margin-top: 82px;
            }
            .mid-wrap .trees-count figure {
              margin-bottom: 12px;
            }
          }
          @keyframes shine {
            100% {
              left: 200px;
            }
          }
          @keyframes back-and-forth {
            from {
              transform: translateX(-3px) rotate(45deg);
            }
            50% {
              transform: translateX(0) rotate(45deg);
            }
            to {
              transform: translateX(-3px) rotate(45deg);
            }
          }
        `}</style>
      </footer>
      <CookieBanner />
      <TermsAndPrivacyPopUp
        id="terms-of-use"
        url={`${assetsBaseUrl}/next/json/en/terms/content.json`}
      />
      <TermsAndPrivacyPopUp
        id="privacy-policy"
        url={`${assetsBaseUrl}/next/json/en/privacy/content.json`}
      />
    </>
  );
}
