import React, { useEffect, useState } from "react";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "@styles/cssinjs/helpers/variables.js";

const CookieBanner = () => {
  const [isCookieBannerVisible, setIsCookieBannerVisible] =
    useState<boolean>(false);
  const setCookieAndHide = () => {
    if (
      localStorage.getItem("cookies_enabled") === null ||
      localStorage.getItem("cookies_enabled") === "0" ||
      localStorage.getItem("cookies_enabled") === ""
    ) {
      localStorage.setItem("cookies_enabled", "1"); // Use cookies
      setIsCookieBannerVisible(false);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (localStorage.getItem("cookies_enabled") !== "1") {
        setIsCookieBannerVisible(true);
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      <div
        id="cookie-banner"
        className={`cookie-banner ${isCookieBannerVisible ? "visible" : ""}`}
      >
        <p>
          &#x1F36A; We want to provide you with the best experience. By using
          this site, you agree to our{" "}
          <a
            href="https://www.setmore.com/privacy#information-collected-automatically"
            rel="noopener noreferrer"
            target="_blank"
          >
            cookie policy
          </a>
          .{" "}
          <button id="cookieConsentOK" onClick={setCookieAndHide}>
            Got it
          </button>
        </p>
        <style jsx>{`
          .cookie-banner {
            width: 100%;
            height: auto;
            background: ${colors.primitive.grey[700]};
            box-shadow: 0 -1px 0 ${colors.primitive.shadow.black};
            padding: 4px 0;
            position: fixed;
            bottom: 0;
            z-index: 1;
            transform: translateY(100%);
            transition: transform 0.3s ease-in-out;
          }
          .cookie-banner.visible {
            transform: translateY(0);
          }
          .cookie-banner p {
            max-width: 1160px;
            margin-left: auto;
            margin-right: auto;
            text-align: left;
            font-weight: ${font.weight.normal};
            font-size: ${font.size.sm[3]};
            line-height: ${lineHeight[6]};
            color: ${colors.primitive.grey[300]};
          }
          .cookie-banner p a {
            text-decoration: underline;
          }
          .cookie-banner button {
            background: none;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            color: ${colors.derived.brand};
          }

          @media (max-width: ${viewport.desktop.sm}) {
            .cookie-banner p {
              margin-left: 18px;
              margin-right: 18px;
            }
          }
        `}</style>
      </div>
    </>
  );
};

export default CookieBanner;
