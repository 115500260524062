import React from "react";
import Link from "next/link";

type ExternalLinkProps = {
  href: string;
  id?: string;
  className?: string;
  children: React.ReactNode;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export function ExternalLink({
  id,
  className,
  href,
  children,
}: ExternalLinkProps) {
  return (
    <Link
      className={className}
      href={href}
      id={id}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </Link>
  );
}
