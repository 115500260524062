import { useEffect, useState, useRef, useCallback } from "react";

const TermsPrivacyList = ({ data }) => {
  return data.map((terms) => (
    <article key={terms.id}>
      <h4>{terms.title}</h4>
      {Array.isArray(terms.desc) ? (
        terms.desc.map((para, index) => (
          <p
            key={`${terms.id}_${index}`}
            dangerouslySetInnerHTML={{ __html: para }}
          />
        ))
      ) : (
        <p dangerouslySetInnerHTML={{ __html: terms.desc }} />
      )}
    </article>
  ));
};

function useStateRef(initialValue) {
  const [data, setData] = useState(initialValue);

  const dataRef = useRef(data);

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  return [data, setData, dataRef];
}

export default function TermsAndPrivacyPopUp({ id, url }) {
  const [data, setData, dataRef] = useStateRef(null);
  const popUpRef = useRef(null);
  const fetchPopUpDataAndSetData = useCallback(async () => {
    const response = await fetch(url);
    if (response.ok) {
      const json = await response.json();
      setData(json.content);
      openPopUp();
    } else {
      console.log(`Error fetching the popup with id ${id}`, response);
    }
  }, [id, setData, url]);

  const openPopUp = () => {
    if (popUpRef) {
      popUpRef.current.classList.remove("hide-terms-privacy-popup");
      popUpRef.current.classList.add("show-terms-privacy-popup");
    }
  };

  const closePopUp = () => {
    if (popUpRef) {
      popUpRef.current.classList.remove("show-terms-privacy-popup");
      popUpRef.current.classList.add("hide-terms-privacy-popup");
    }
  };

  const fetchDataAndOpenPopUp = useCallback(() => {
    if (dataRef.current === null) {
      fetchPopUpDataAndSetData();
    } else {
      openPopUp();
    }
  }, [dataRef, fetchPopUpDataAndSetData]);

  useEffect(() => {
    if (window.location.hash.slice(1) === id) {
      fetchDataAndOpenPopUp();
    }
    window.addEventListener("hashchange", () => {
      let urlHash = window.location.hash.slice(1);
      if (urlHash === id) {
        fetchDataAndOpenPopUp();
      }
      if (
        urlHash !== id &&
        popUpRef.current?.classList.contains("show-terms-privacy-popup")
      ) {
        closePopUp();
      }
    });
  }, [fetchDataAndOpenPopUp, id]);

  return (
    <article id={id} className={`hide-terms-privacy-popup`} ref={popUpRef}>
      {data && (
        <>
          <div className="modal-content">
            <div className="header">
              <div id="privacy-policy-terms-privacy-head">
                <h3 dangerouslySetInnerHTML={{ __html: data.title }} />
              </div>
              <a
                href="#footer"
                className="icon-close popup-close icon-cross"
                onClick={(e) => {
                  let parentElement = e.target.closest(`#${id}`);
                  parentElement.classList.remove("show-terms-privacy-popup");
                  parentElement.classList.add("hide-terms-privacy-popup");
                }}
              ></a>
            </div>
            <div className="copy">
              <TermsPrivacyList data={data.list} />
            </div>
          </div>
          <div className="overlay"></div>
        </>
      )}
      <style jsx>{`
        :global(#${id}.show-terms-privacy-popup) {
          position: fixed;
          opacity: 1;
          visibility: visible;
          z-index: 100;
        }
        :global(#${id}.hide-terms-privacy-popup) {
          position: fixed;
          opacity: 0;
          visibility: visible;
          z-index: -1;
        }
      `}</style>
    </article>
  );
}
